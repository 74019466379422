import React from 'react';
import GroupBlock from "./GroupBlock";
import {GridProps} from "@mui/system";
import {formatedDataLessons} from "@/shared/interfaces/lesson";
import {MainClock} from "./MainClock";

interface CustomGridProps extends GridProps {
    lessons: formatedDataLessons,
    page: number,
    currentNumber: number,
    grid_size_h: number,
    grid_size_w: number
    showClock: boolean
}

function pages(obj: formatedDataLessons, page: number, step=9, showClock?: boolean) {
    const clockInd = showClock?1:0;
  return Object.keys(obj)
      .slice(page === 1? 0 : step*(page-1)-clockInd, page === 1 ? step*page - clockInd: step*page)
      .reduce((memo: formatedDataLessons, current) =>{
        memo[current] = obj[current];
        return memo;
      }, {})
}

const CustomGrid: React.FC<CustomGridProps> = (props) => {
    const {lessons, page, grid_size_h, grid_size_w, showClock } = props;
    const data = pages(lessons, page, grid_size_h*grid_size_w, showClock);
    return (
        <>
            {showClock && page===1?<MainClock  grid_size_h={grid_size_h} grid_size_w={grid_size_w} />:null}
            {lessons?Object.values(data).map((lesson, index) => <GroupBlock
                key={index}
                data={lesson}
                grid_size_h = {grid_size_h}
                grid_size_w = {grid_size_w}
            />):null}
        </>
    )
}

export default CustomGrid;