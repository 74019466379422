import React from 'react';
import { styled } from '@mui/system';
import {Typography} from "@mui/material";
import _ from 'lodash';
import Lesson from "./Lesson";
import {formatedDataLesson} from "@/shared/interfaces/lesson";

type GroupProps = Pick<GroupBlockProps['data'], 'course'>

type ItemProps = Pick<GroupBlockProps, 'grid_size_h' | 'grid_size_w'>

type GroupBlockProps = {
  data: formatedDataLesson,
  grid_size_h: number,
  grid_size_w: number
}

const Item = styled('div')((props:ItemProps) => ({
  flexGrow: 0,
  display: 'flex',
  alignItems: 'stretch',
  flexDirection: 'column',
  flexWrap: 'nowrap',
  height: `calc( 100vh / ${props.grid_size_h} - .1px)`,
  width: `calc( 100vw / ${props.grid_size_w} - .1px)`,
  border: `1px solid #e0e7e8`,
  borderLeft: `none`,
  borderTop: `none`,
  backgroundColor: '#fff'
}));

const Group = styled(Typography)((props:GroupProps)=>({
  fontFamily: 'CirceGlyphs',
  fontWeight: 600,
  borderBottom: '1px solid #e0e7e8',
  // eslint-disable-next-line eqeqeq
  background: (props.course == 1?
      `var(--yellow)`:
      // eslint-disable-next-line eqeqeq
      (props.course == 4?
          `var(--red)`:
          // eslint-disable-next-line eqeqeq
          (props.course == 3?
              `var(--orange)`:
              `var(--green)`))),
  color: '#fff',
  textShadow: '1px 0px 0px #000',
  //textStroke: '1px #000',
  webkitTextStroke: '4px #000',
  fontSize: '2.8rem',
  marginBottom: '0.2rem'
}));

const GroupBlock: React.FC<GroupBlockProps> = (props) => {
  const {data, grid_size_h, grid_size_w} = props;
  const lessons = _.omit(data, ['course', 'group']);
  return (
    <Item grid_size_h={grid_size_h} grid_size_w={grid_size_w}>
      <Group variant="h3" gutterBottom className={'group'} course={data.course}>
        {data.group}
      </Group>
      {Object.keys(lessons).map((elem: any) =>
          (<Lesson data={lessons[elem]} number={elem} key={elem}/>)
      )}
    </Item>
  )
}

export default GroupBlock;