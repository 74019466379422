import React, { useCallback, useEffect, useState} from 'react';
import TemporaryDrawer from "./components/Drawer";

import 'typeface-roboto';
import './styles/App.css';
import CustomGrid from "./components/CustomGrid";
import _ from 'lodash';
import {LessonContext} from "./context/lessons-context";
import {DateRange} from "moment-range";

import Moment from 'moment';
import { extendMoment } from 'moment-range';
import {formatedDataLessons, IServerDataLesson} from "./shared/interfaces/lesson";
import {IBellsScheduleRecord} from "./shared/interfaces/bellSchedule";

// @ts-expect-error
const moment = extendMoment(Moment);

const getOutputData = (number: number, rawData: IServerDataLesson[]):formatedDataLessons => {
  const results: IServerDataLesson[] = _.orderBy(rawData.filter((e) => {
    return e.number === number || e.number === number + 1;
  }), ['course', 'group']);

  return results.reduce((acc:formatedDataLessons, item: IServerDataLesson) => ({
    ...acc,
    [item.group]: {
      ...acc[item.group],
      group: item.group,
      course: item.course,
      [item.number]: [
        ...((acc[item.group] && acc[item.group][item.number]) || []),
        item,
      ]
    }
  }), {});
};

export const AppNext = () => {
  const [result, setResult] = useState<IServerDataLesson[]>([]);
  const [campus, setCampus] = useState<string>('ОЦ');
  const [page, setPage] = useState<number>(1);
  const [number, setNumber] = useState<number>(1);
  const [type, setType] = useState<number>(1);
  const [shortSchedule, setShortSchedule] = useState<boolean>(false);
  const [bellsSchedule, setBellSchedule] = useState<IBellsScheduleRecord>({});
  const [grid_size_h, setGridSizeHeight] = useState<number>(2);
  const [grid_size_w, setGridSizeWidth] = useState<number>(3);
  const [showClock, setShowClock] = useState<boolean>(true);


  const getSchedule = useCallback(async (campus: string) =>{
    try {
      const response = await fetch(`/api/schedule?campus=${campus}`)
      const res = await response.json();
      setResult(res.result);
      if(!shortSchedule) {
        setType(res.type);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, [bellsSchedule]);

  const getLessonNumber = useCallback(() => {
    let lessonNumber = number;
    let now = moment();

    if(!_.isEmpty(bellsSchedule)) {
      let timeRangeAM = moment().range(moment('00:00', 'HH:mm Z'), moment(bellsSchedule[`1`].start, 'HH:mm Z'));
      let timeRangePM = moment().range(moment(bellsSchedule[`7`].end, 'HH:mm Z'), moment('23:59', 'HH:mm Z'));
      if (now.within(timeRangeAM)) {
        lessonNumber = 1;
      } else {
        if (now.within(timeRangePM)) {
          lessonNumber = 7;
        } else {
          for (let index in bellsSchedule) {
            const ind = parseInt(index);
            const {start, end} = bellsSchedule[`${ind}`];
            const {start: startNext} = bellsSchedule[`${ind+1}`]||end;
            let timeRange: DateRange = moment().range(moment(start, 'HH:mm Z').subtract(10, 'm'), moment(startNext??end, 'HH:mm Z'));
            //console.table(timeRange);
            lessonNumber = now.within(timeRange) ? ind : lessonNumber;
          }
        }
      }
    }
    setNumber(lessonNumber);
  }, [result, bellsSchedule]);

  const getLessons = useCallback(async (type: number) => {
    try {
      const response = await fetch(`/data.json`);
      const data = await response.json();
      setBellSchedule(type === 1 ? data[0] : data[1]);
      // getLessonNumber();
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, []);

  useEffect(()=>{
    getLessons(type);
    getSchedule(campus);
  }, []);

  useEffect(()=>{
    getLessonNumber();
  }, [bellsSchedule, result]);

  useEffect(()=>{
    const timer = setInterval(()=> getSchedule(campus), 60000);
    return () => clearInterval(timer);
  }, [campus]);

  useEffect(()=>{
    getSchedule(campus);
  }, [campus]);

  useEffect(()=>{
    getLessons(type);
  }, [type]);

  return (
      <div className="App">
          <TemporaryDrawer
              className="Drawer"
              campus = {campus}
              grid_size_w = {grid_size_w}
              grid_size_h = {grid_size_h}
              handleChangeGridWidth = {setGridSizeWidth}
              handleChangeGridHeight = {setGridSizeHeight}
              handleChangeCampus={setCampus}
              page={page}
              handlerChangePage={setPage}
              type={type}
              shortSchedule={shortSchedule}
              bellsSchedule={bellsSchedule}
              handleChangeLength={setShortSchedule}
              handlerChangeType={setType}
              showClock={showClock}
              handleChangeShowingClock={setShowClock}
          />
        <LessonContext.Provider value={{number, type, grid_size_h, grid_size_w, bells: bellsSchedule}}>
          <CustomGrid
              className="Grid"
              lessons={getOutputData(number, result)}
              page={page}
              currentNumber={number}
              grid_size_h = {grid_size_h}
              grid_size_w = {grid_size_w}
              showClock={showClock}
          />
        </LessonContext.Provider>
      </div>
  );
}

export default AppNext;
