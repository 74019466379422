import React, {useEffect, useState} from "react";
import {styled} from "@mui/system";
import {Typography} from "@mui/material";

type ClockBlockProps = {
    grid_size_h: number,
    grid_size_w: number
}

const ItemClock = styled('div')((props:ClockBlockProps) => ({
    flexGrow: 0,
    display: 'flex',
    alignItems: 'stretch',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    height: `calc( 100vh / ${props.grid_size_h} - .1px)`,
    width: `calc( 100vw / ${props.grid_size_w} - .1px)`,
    border: `1px solid #e0e7e8`,
    borderLeft: `none`,
    borderTop: `none`,
    backgroundColor: '#fff',
    justifyContent: 'center',
}));

export const MainClock: React.FC<ClockBlockProps> = (props) => {
    const [dt, setDt] = useState('');
    const [tm, setTm] = useState<React.JSX.Element>(<></>);
    function showTime() {
        const date = new Date();
        const days = [
            "воскресенье",
            "понедельник",
            "вторник",
            "среда",
            "четверг",
            "пятница",
            "суббота"
        ];
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth()+1).toString().padStart(2, '0');
        const year = date.getFullYear();
        const day_name = days[date.getDay()];
        const dt = day + "." + month + "." + year + " " + day_name.toUpperCase();

        let hours = date.getHours(),
            minutes = date.getMinutes();

        let tm = <>{hours.toString().padStart(2, '0')}<span className="blink">:</span>{minutes.toString().padStart(2, '0')}</>;

        setDt(dt);
        setTm(tm);
    }

     useEffect(()=>{
        const timer = setInterval(()=> showTime(), 1000);
        return () => clearInterval(timer);
    }, []);
    return (
        <ItemClock {...props}>
            <Typography variant="h5" gutterBottom>
                {dt}
            </Typography>
            <Typography variant="h1" gutterBottom fontWeight={"600"} fontSize={"8em"}>
                {tm}
            </Typography>
        </ItemClock>
    );
}