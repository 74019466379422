import React from "react";

type LessonContextType = {
  type: number,
  number: number,
  bells: {[key: string]: {start?: string, end?: string}},
  grid_size_h: number,
  grid_size_w: number
}

export const LessonContext = React.createContext<LessonContextType>({
  type: 1,
  number: 1,
  bells: {},
  grid_size_h: 2,
  grid_size_w: 3
});