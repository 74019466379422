import * as React from 'react';
import Drawer, {DrawerProps} from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Grid from '@mui/material/Grid2';
import {Accordion, AccordionDetails, AccordionSummary, Box, Checkbox, TextField, Typography} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {useState} from "react";
import {styled} from "@mui/system";
import {IBellsScheduleRecord} from "@/shared/interfaces/bellSchedule";

interface TemporaryDrawerProps extends DrawerProps {
    campus: string,
    handleChangeCampus:  React.Dispatch<React.SetStateAction<string>>,
    grid_size_w: number,
    handleChangeGridWidth: React.Dispatch<React.SetStateAction<number>>,
    grid_size_h: number,
    handleChangeGridHeight: React.Dispatch<React.SetStateAction<number>>,
    page: number,
    handlerChangePage: React.Dispatch<React.SetStateAction<number>>,
    shortSchedule: boolean,
    handleChangeLength: React.Dispatch<React.SetStateAction<boolean>>,
    type: number,
    handlerChangeType: React.Dispatch<React.SetStateAction<number>>,
    bellsSchedule: IBellsScheduleRecord,
    showClock: boolean,
    handleChangeShowingClock: React.Dispatch<React.SetStateAction<boolean>>
}

const drawerWidth:number = 550;

const DrawerFormControl = styled(FormControl)(({theme})=>({
    margin: theme.spacing(1),
    minWidth: 120,
}));

const Root = styled('div')(()=>({
    display: 'flex',
    position: 'absolute'
}));

const TemporaryDrawer:React.FC<TemporaryDrawerProps> = (props) => {
    const [open, setOpen] = useState<boolean>(false);

    const toggleDrawer = (newOpen: boolean) => ():void => {
        setOpen(newOpen);
    }

    const DrawerList = (
        <Box sx={{ width: drawerWidth, flexShrink: 1}} role='presentation'>
            <Button sx={{width: '100%'}} onClick={toggleDrawer(false)}>Close</Button>
            <List>
                <ListItem key={`campus`}>
                    <DrawerFormControl>
                        <InputLabel id="campus-simple">Корпус</InputLabel>
                        <Select
                            labelId="campus-simple"
                            value={props.campus}
                            label={"Корпус"}
                            onChange={e => {props.handleChangeCampus(e.target.value)}}
                        >
                            <MenuItem value={`ОЦ`}>ОЦ</MenuItem>
                            <MenuItem value={`ПМК`}>ПМК</MenuItem>
                        </Select>
                    </DrawerFormControl>
                </ListItem>
            </List>
            <Divider />
            <List>
                <ListItem key={`grid_size`}>
                    <Grid container key={`grid_size`} spacing={1}>
                        <Grid size={{xs: 12}}>
                            <Typography marginBottom={'10px'}>
                                Размер сетки
                            </Typography>
                        </Grid>
                        <Grid container spacing={`1`}>
                            <Grid size={`auto`}>
                                <TextField
                                    id="grid_size_w"
                                    label="Ширина"
                                    value={props.grid_size_w}
                                    onChange={(e) => props.handleChangeGridWidth(Number(e.target.value))}
                                />
                            </Grid>
                            <Grid size={1}>
                                <Typography>Х</Typography>
                            </Grid>
                            <Grid size={`auto`}>
                                <TextField
                                    id="grid_size_h"
                                    label="Высота"
                                    value={props.grid_size_h}
                                    onChange={(e) => props.handleChangeGridHeight(Number(e.target.value))}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </ListItem>
                <ListItem key={`course`}>
                    <DrawerFormControl>
                        <InputLabel id="course-simple">Страница</InputLabel>
                        <Select
                            value={props.page}
                            onChange={(e) => props.handlerChangePage(Number(e.target.value))}
                            labelId={"course-simple"}
                            label={"Страница"}
                        >
                            <MenuItem value={1}>1</MenuItem>
                            <MenuItem value={2}>2</MenuItem>
                            <MenuItem value={3}>3</MenuItem>
                            <MenuItem value={4}>4</MenuItem>
                            <MenuItem value={5}>5</MenuItem>
                        </Select>
                    </DrawerFormControl>
                </ListItem>
                <ListItem key={'showClock'}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={props.showClock}
                                onChange={() => props.handleChangeShowingClock(!props.showClock)}
                                value={true}
                            />
                        }
                        label={"Отображать часы"}
                    />
                </ListItem>
            </List>
            <Divider/>
            <List>
                <ListItem key={'length'}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={props.shortSchedule}
                                onChange={() => props.handleChangeLength(!props.shortSchedule)}
                                value={true}
                            />
                        }
                        label={"Принудительно установить тип"}
                    />
                </ListItem>
                <ListItem key={`type`}>
                    <DrawerFormControl>
                        <InputLabel id="type-simple">Тип расписания</InputLabel>
                        <Select
                            value={props.type}
                            onChange={(e) => props.handlerChangeType(Number(e.target.value))}
                            labelId={`type-simple`}
                            label={`Тип расписания`}
                        >
                            <MenuItem value={0}>Сокращенное</MenuItem>
                            <MenuItem value={1}>Стандартное</MenuItem>
                        </Select>
                    </DrawerFormControl>
                </ListItem>
            </List>
            <Divider/>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header">
                    <Typography>Настройка расписания</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{alignItems: `left`}}>
                    {Object.entries(props.bellsSchedule).map(([key, value])=> {return (
                        <Grid container key={key} spacing={1} marginBottom={`10px`}>
                            <Grid size={2}>
                                <Typography variant={"body2"} gutterBottom>
                                    Пара {key}
                                </Typography>
                            </Grid>
                            <Grid size={5}>
                                <TextField id="start[]" label="Старт" value={value.start} />
                            </Grid>
                            <Grid size={5}>
                                <TextField id="end[]" label="Окончание" value={value.end} />
                            </Grid>
                        </Grid>
                    )})}
                </AccordionDetails>
            </Accordion>
        </Box>
    );

    return (
        <Root>
            <Button onClick={toggleDrawer(true)} sx={{color: 'transparent'}}>Open Left</Button>
            <Drawer
                variant="persistent"
                anchor="left"
                open={open}
                onClose={toggleDrawer(false)}
            >
                {DrawerList}
            </Drawer>
        </Root>
    );
}

export default TemporaryDrawer;